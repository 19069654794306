<template>
	<div>
		<v-app-bar prominent app :short="true" elevation="1" id="hdr" :dark="true">
			<v-row dense>
				<v-col>
					<v-row dense>
						<v-col>
							<h2 style="color: white; text-align: center">Settings</h2>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col>
							<h5 style="color: white; text-align: center">
								Customize the rolling scoreboard
							</h5>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-app-bar>
		<v-list class="backBleed" flat>
			<v-list-item-group>
				<v-list-item
					v-for="(setting, idx) in settings"
					:key="setting.title"
					:class="SelectedItem == idx ? 'highlighted' : ''"
					@mouseover="SelectedItem = idx"
					@click="select(setting)"
				>
					<v-list-item-avatar :color="SelectedItem == idx ? 'primary' : ''">
						<v-icon v-show="SelectedItem == idx"> mdi-target </v-icon>
						<v-icon v-show="setting.leftIcon && !SelectedItem == idx">
							{{ setting.leftIcon }}
						</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<h2 class="my-1 mb-2">
							{{ setting.title }}
						</h2>
					</v-list-item-content>
					<v-list-item-icon>
						<div v-if="setting.rightIcon">
							<v-icon v-if="typeof setting.rightIcon === 'function'">
								{{ setting.rightIcon() }}
							</v-icon>
							<v-icon v-else> {{ setting.rightIcon }} </v-icon>
						</div>
						<span v-else-if="setting.state">
							{{ ScoreOptions[SelectedScores] }}
						</span>
					</v-list-item-icon>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</div>
</template>

<script>
export default {
	data() {
		return {
			ScoreOptions: ['Latest Event', "Today's Event", 'All Events'],
			SelectedScores: 0,
			DarkStatusOpen: false,
			settings: [
				{
					to: '/',
					title: 'Back',
					rightIcon: '',
					leftIcon: 'mdi-undo',
				},
				{
					to: '/settings/announcements',
					title: 'Announcements',
					rightIcon: 'mdi-chevron-right',
				},
				{
					to: '/settings/scroll',
					title: 'Scrolling',
					rightIcon: 'mdi-chevron-right',
				},
				{
					title: 'Show Events',
					subtitle: '',
					visible: true,
					to: () => {
						this.SelectedScores++;
						if (this.SelectedScores >= this.ScoreOptions.length)
							this.SelectedScores = 0;
						this.$store.dispatch(
							'set_eventscores',
							this.ScoreOptions[this.SelectedScores]
						);
					},
					state: true,
				},
				{
					to: () => {
						this.$store.dispatch('set_hoahaa', !this.$store.state.ShowHOAHAA);
					},
					title: 'Show HOA/HAA',
					rightIcon: () => {
						return this.$store.state.ShowHOAHAA
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
				},
				{
					to: () => {
						this.$store.dispatch(
							'set_displayclasses',
							!this.$store.state.DisplayClasses
						);
					},
					title: 'Display Classes',
					rightIcon: () => {
						return this.$store.state.DisplayClasses
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
				},
				{
					to: () => {
						this.$store.dispatch(
							'set_categories',
							!this.$store.state.DisplayCategories
						);
					},
					title: 'Display Categories',
					rightIcon: () => {
						return this.$store.state.DisplayCategories
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
				},
				{
					to: () => {
						this.$store.dispatch(
							'set_showwinner',
							!this.$store.state.ShowWinners
						);
					},
					title: 'Show Winners on Completed Events',
					rightIcon: () => {
						return this.$store.state.ShowWinners
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
				},
			],
			SelectedItem: 0,
		};
	},
	methods: {
		select(item) {
			if (typeof item.to === 'function') item.to();
			else this.$router.push(item.to);
		},
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'ArrowLeft':
				case 'ArrowUp':
					if (this.SelectedItem == 0) this.SelectedItem = this.settings.length;
					this.SelectedItem--;
					break;
				case 'ArrowRight':
				case 'ArrowDown':
					if (this.SelectedItem == this.settings.length - 1)
						this.SelectedItem = -1;
					this.SelectedItem++;
					break;
				case 'Enter':
					this.select(this.settings[this.SelectedItem]);
					break;
				case 'Escape':
					this.$router.push('/settings');
					break;
			}
		},
	},

	mounted() {
		this.SelectedScores = this.ScoreOptions.indexOf(
			this.$store.state.EventScores
		);
		if (this.SelectedScores == -1) this.SelectedScores = 0;
		this.DarkStatusOpen = this.$vuetify.theme.dark;
		this.$vuetify.theme.dark = true;
		window.addEventListener('keydown', this.keyboardShortcuts);
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = this.DarkStatusOpen;
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
};
</script>

<style scoped>
.highlighted {
	background: #24598c;
}
</style>
